.mapandListing_card {
  font-family: "Montserrat";
  cursor: pointer;
  width: 280px;
}

.main_cardmi {
  width: 100%;
  border-radius: 12px !important;
  border: 1px solid #d9d9d9 !important;
  box-shadow: none !important;
  position: relative;
}

.main_cardmi:hover {
  border: 1px solid #aaaaaa !important;
}

.cardmain_img {
  width: 270px;
  height: 180px;
  position: relative;
}

.Image_Overlaysetting {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

.card_abitem {
  color: #fff;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  margin-left: 7px !important;
}

.type_btn {
  outline: none;
  border: none;
  color: #282828;
  font-family: Montserrat;
  border-radius: 3px !important;
  background: #CFE5FD;
  padding: 4px 10px !important;
  font-size: 13px !important;
}

.typeandDate_card {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}

.cardMain_date {
  font-family: Montserrat;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.card_posted {
  color: #02549e;
}

.card_price2 {
  font-size: 13px;
  font-weight: 700;
  color: #ca0024;
  margin: 0px !important;
}

.waspricee_sqft {
  font-weight: 600;
  font-size: 9px;
  color: #ca0024;
  text-decoration: line-through;
}

.cutwas_price {
  text-decoration: line-through;
}

.nowpricee_sqft {
  color: #000;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.card_now {
  color: #02549e;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.card_project {
  font-size: 14px;
  font-weight: 700;
  padding: 0px;
  color: #121212;
  margin-top: 10px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.cardmain_highlight {
  font-family: Montserrat !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  color: #121212 !important;
  line-height: 147% !important;
  margin-top: 10px !important;
}

.card_view {
  color: #02549e;
  font-family: Montserrat;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 147%;
}

.heart_compareMain {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 45px;
  border-top: 1px solid #d9d9d9;
}

.grey_card {
  background-color: #d9d9d9;
  padding-left: 8px;
  border-radius: 4px;
}

.cardMain_compare {
  color: #424242;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 147%;
}

.compare_box {
  width: 3em;
  height: 2.2em;
}

.web_closehide {
  display: none;
}

.css-1t6e9jv-MuiCardActions-root {
  padding: 6px 11px 6px 11px !important;
}

.css-3zukih {
  padding: 6px 11px 6px 11px !important;
}

.css-dgyimw {
  border-radius: 8px !important;
}

.compareModal_main {
  display: flex;
  justify-content: center;
  width: 100%;
}

.HighlightCard_bg {
  background-color: #A7E7FF !important;
}

.wasandnow_price {
  margin-top: 10px !important;
}

@media (max-width: 800px) {
  .mapandListing_card {
    width: 354px;
  }

  .cardmain_img {
    width: 344px;
    height: 180px;
    position: relative;
  }

  .maindataxx {
    padding: 4px 0.5rem 20px 0.5rem !important;
    height: auto !important;
  }

  .cardpara2 {
    font-size: 13px !important;
  }

  .typeandDate_card {
    display: flex;
    justify-content: flex-start;
  }

  .cardMain_date {
    margin-left: 0.7rem !important;
    padding-top: 0.4rem;
  }

  .mb_postdate {
    display: none;
  }

  .mb_closehide {
    display: none;
  }

  .card_price2 {
    font-size: 12px;
    font-weight: 600;
  }

  .waspricee_sqft {
    font-size: 10px;
    margin: 0px !important;
    padding-left: 2px !important;
    font-weight: 600;
  }

  .nowpricee_sqft {
    font-size: 12px;
    font-weight: 600;
    margin: 0px !important;
    padding-left: 4px !important;
  }

  .card_now {
    font-size: 16px;
    padding: 0px;
    margin: 0px;
  }

  .wasandnow_price {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin: 10px 0 0 0 !important;
  }

  .now_main {
    width: fit-content;
    padding: 0px !important;
    margin: 0px !important;
    display: flex;
    align-items: flex-end;
  }

  .Was_hide {
    display: none;
  }

  .was_mb {
    padding-left: 10px !important;
  }

  .card_project {
    font-size: 14px !important;
  }

  .cname {
    font-size: 14px !important;
  }

  .cardmain_highlight {
    font-size: 13px !important;
  }

  .heart_compareMain {
    border: none;
    height: 0px;
  }

  .heartandShare {
    position: absolute;
    top: 180px;
    right: 1px;
    display: flex;
    flex-direction: row-reverse;
  }

  .one {
    display: none;
  }

  .heart_Iconmb {
    padding: 0px !important;
  }

  .mb_closehide {
    display: block;
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}

@media (max-width: 512px) {
  .mapandListing_card {
    width: 93%;
    margin-bottom: 12px;
  }

  .cardmain_img {
    width: 98%;
  }

}