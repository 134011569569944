.greencolor {
  background-color: #02549e;
}


.nav-link:hover {
  font-weight: 550;

}


.nav-link:active,
.nav-link.active {
  font-weight: 550;
}

.Contactimagezz {
  width: 446px;
  height: 400px;
}

.tagwidth {
  width: 11%;
}

.tagwidth0 {
  width: 9%;
}

.tagwidth2 {
  width: 12.5%;
}

.tagwidth3 {
  width: 13%;
}

.tagwidth4 {
  width: 15%;
}


.fontzz {
  font-family: "Montserrat";
  font-size: 15px;
}

.nav-link {
  font-size: 16px;
  font-weight: 500;
}

.menuwidth {
  width: 200px;
  height: 350px;
  overflow-y: scroll;

}

.menuwidth1 {
  width: 280px;

}

.hearingIcon {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #02549e;
  width: 61px;
  border-radius: 7px;
  height: 45px;
  align-items: center;
}

.duphearingIcon {
  display: flex;
  flex-direction: column;
  background-color: "#EEEEEE";
  width: 61px;
  border-radius: 7px;
  height: 45px;
  align-items: center;
}

.hearingIcon1 {
  font-size: 1.5rem;
  color: #02549e;
}

.duphearingIcon1 {
  font-size: 1.5rem;
  color: #8B8787;
}

.hearingIcon2 {
  font-size: 0.8rem;
  color: #02549e;
  margin: 0;
}

.duphearingIcon2 {
  font-size: 0.8rem;
  color: #8B8787;
  margin: 0;
}

.marqmarquez {
  background-color: #02549e;
  color: white;
  font-size: 14px;
  font-weight: 500;
  padding: 4px;
}

.marqmarquez .child {
  background-color: #02549e;
  color: white;
  font-size: 14px;
  font-weight: 500;
  padding: 0 80px;
}

.buttonsize {
  font-size: 16px;
  margin-top: 10px;
}

.textwhite {
  color: white;
}

.greencategory {
  background-color: #02549e;
  font-family: Montserrat;
}

.btngreencolor {
  background-color: #02549e ;
  color: white;
}

.btngreencolor:hover {
  background-color: #02549e;
  color: white;
}

.sorting_blueBtn{
  background-color: #02549e !important;
  color: white  !important;
  border: none !important;
}

.btngraycolor {
  background-color: #eeeeee;
  margin-left: 6px;
  color: rgb(0, 0, 0);
  width: 59px;
  height: 46px;
}

.fbton {
  background-color: #ffffff;
  color: #02549e;
  margin-right: 40px;
  height: 40px;
  font-weight: 700;
  font-size: 16px;
}

.fbton:hover {
  background-color: #ffffff;
  color: #02549e;
  margin-right: 40px;
  height: 40px;
  font-weight: 700;
  font-size: 16px;
}

.flot {
  float: right;
}

.whitecolor {
  background-color: white;
}

.iconsetting {
  color: #02549e;
  font-size: 22px;
}

.roundicon {
  color: white;
  background-color: #676767;
  font-size: 25px;
  padding: 5px;
  border-radius: 50%;
}

.purplebtn {
  background-color: #6c35a3;
  color: white;
  font-size: 12px;
}

.icmargin {
  margin-left: 5px;
}

.iconfilled {
  color: #f6941c;
}

.iconfiller {
  color: #02549e;
}

.blueiconfiller {
  color: #02549e;
  font-size: 10px;
}

.textgreen {
  color: #02549e;
}

.cardbtn {
  background-color: #6c35a2;
  color: #6c35a2;
}

.Submitbutton {
  background-color: #02549e;
  color: white;
  border: 0px;
  height: 40px;
  padding: 4px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  font-family: "Montserrat";
}

.Submitbutton__drawer {
  background-color: #02549e;
  color: white;
  border: 0px;
  height: 40px;
  padding: 4px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  font-family: "Montserrat";
}

.Comparebutton {
  background-color: #02549e;
  color: white;
  border: 0px;
  height: 40px;
  padding: 4px;
  border-radius: 4px;
  font-size: 16px;
  font-family: "Montserrat";
}

.EraseComparison {
  background-color: #ca0024;
  color: white;
  border: 0px;
  height: 40px;
  padding: 4px;
  border-radius: 4px;
  font-size: 16px;
  font-family: "Montserrat";
}

.EraseCompare_button {
  background-color: #fff;
  border: 0px;
  padding: 5px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Detailsbutton {
  background-color: #02549e;
  color: white;
  border: 0px;
  height: 40px;
  padding: 4px 10px 4px 10px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  font-family: "Montserrat";
}

.detButtonCursor {
  cursor: default !important;
}

.linkline {
  text-decoration: none;
}

.loader-container {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  height: 100%;
}

.background {
  background-color: #0253a0;
  color: #c0d4e7;
}

.Detailsbut {
  background-color: #02549e;
  color: white;
  border: 0px;
  height: 30px;
  padding: 4px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  font-family: "Montserrat";
}

.Borderbutton {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  border: 3px solid #CCCCCC;
  height: 40px;
  padding: 4px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;

  font-family: "Montserrat";
}

.Borderbut {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  border: 3px solid #CCCCCC;
  height: 33px;
  padding: 4px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 10px;

  font-family: "Montserrat";
}

.BorderBluebutton {
  background-color: #ffffff;
  color: #0253a0;
  border: 3px solid #c0d4e7;
  height: 40px;
  padding: 4px;
  border-radius: 8px;
  font-size: 16px;

  font-family: "Montserrat";
}

.Agentbutton {
  background-color: #02549e;
  color: white;
  border: 4px;
  height: 40px;
  width: 228px;
  padding: 4px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  font-family: "Montserrat";
}



.userRole_button {
  background-color: #F8F8F8;
  color: #525252;
  border: 0px;
  height: 44px;
  width: 131px;
  padding: 4px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 20px;
  font-family: "Montserrat";
}


.activeRole_button {
  background-color: #02549e;
  color: #fff;
}

.CreateButton {
  background-color: #eaa800;
  color: #ffffff;
  border: 0px;
  height: 40px;
  width: 386px;
  padding: 4px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  font-family: "Montserrat";
}

.whitegreenbutton {
  color: #02549e;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #02549e;
  padding: 9px 20px 9px 20px;
  font-size: 12px;
  font-weight: 500;
}

.centeralign {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Google_Button {
  font-family: Montserrat !important;
  font-size: 14px !important;
  font-weight: 400px !important;
  background-color: #fff !important;
  color: #333 !important;
  text-transform: capitalize !important;
  border-radius: 4px !important;
  border: 1px solid #D9D9D9 !important;
  padding: 6px !important;
  height: 40px;
  width: 100%;
}



@media (max-width: 800px) {
  .iconsetting {
    color: #ffff;
    font-size: 16px;
  }

  /* .Buyerbutton {
    background-color:  #ffffff !important;
    color: rgb(0, 0, 0) !important;
    border-bottom: 2px solid black;
    height: 44px;
    width: 131px;
    padding: 0px !important;
    border-radius: 4px;
    font-weight: 500;
    font-size: 20px;
    font-family: "Montserrat";
  } */
  .Submitbutton__drawer {
    font-size: 12px !important;
  }

  /* .Sellerbutton {
    background-color: #ffffff !important;
  } */
  .mob_closingDate {
    display: none;
  }
}


@media (max-width: 600px) {
  .fieldsettings {
    width: 300px !important;
  }

  .Phonefieldsettings {
    width: 342px !important;
  }

  .Frequencyfieldsettings {
    width: 350px !important;
  }

  .fieldsettings2 {
    width: 300px !important;
  }

  .CreateButton {

    width: 300px !important;

  }

  .SignUp_submitBtn {
    width: 100% !important;
  }

  .Google_Button {
    width: 300px !important;
  }

  .userRole_button{
    width: 96px;
    font-size: 16px;
  }
}