/* AvatarUpload.css */
.avatar-input {
    display: none;
}

/* Avatar File Upload Wrapper */
.AvatarFileUpload {
    position: relative;
    width: 100px;
    height: 100px;
    background: #f9f9f9;
    border: 3px solid #bbb;
    border-radius: 50%;

}

.profileMargin__adujust {
    margin-top: 1rem
}

/* Selected Image Holder */
.selected-image-holder {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
}

.selected-image-holder img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Image Overlay and Selector */
.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s;
    cursor: pointer;

}

.frequencyTap {
    display: flex;
    justify-content: space-around;
    padding: 5px 10px;
    background: #e9ecef;
    border-radius: 4px;
}

.selected-image-holder:hover .image-overlay {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.6);
}

.avatar-selector-btn {
    width: 46%;
    height: 34%;
    background-color: #ffffff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: center;
    cursor: pointer;
    opacity: 0.7;
}

.upload-icon {
    width: 200px;
    height: 100px;
    object-fit: contain;
}

.avatar-selector-btn img {
    width: 80%;
    height: 80%;
    object-fit: contain;
}

/* Icon for Image Upload */
.avatar-selector-btn:after {
    content: '';
    /* Clear previous content */
    width: 24px;
    height: 24px;
    background-image: url('http: //www.w3.org/2000/svg');
    /* Replace with your icon image URL */
    background-size: cover;
    background-position: center;
}


.profileimg {
    width: 100px;
    height: 100px;
}

.profiledesc {
    line-height: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 2%;
}

.editprofile {
    margin-left: 15%;
    font-family: Montserrat;
    font-weight: 600;
}

.sidemargin {
    margin-left: 3%;
}

h5,
h6 {
    font-size: large;
    font-weight: 600;
    font-family: "Montserrat";
}

p {
    font-weight: 100;
}

.form {
    height: 70vh;
}

.title {
    font-weight: 600;
}

.bold-label {
    font-weight: bold;
}

.visibility {
    display: none;
}

.profile_field {
    max-width: 350px;
    width: 97%;
}


@media (max-width:787px) {
    .profileMargin__adujust {
        margin-top: 4rem !important;
    }
}

@media (max-width: 768px) {

    .ProfilefromContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 15% !important;
    }

    .MobileSideMargin {
        margin-left: 15% !important;

    }

    .profiledesc {
        line-height: 10px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-left: 3%;
    }

    .mobileviewcontainer {
        display: flex;
        flex-direction: column;
    }

    .sidemargin {
        margin: 3%;
    }

    .mobilevisibility {
        display: none !important;
        height: 0;
        width: 0;
    }

    .page-content {
        margin: 0 15% !important;
    }

    .mobile_message_container {
        margin: 0px !important;
    }

    .mobile-savedSearch-container {
        animation: 0.5s ease-in 0s 1 slideInTop;
    }

    .mobile-sidebar-container {
        animation: 0.5s ease-out 0s 1 slideInbottom;
        /* height: 82dvh;
        position: relative; */
    }

    .visibility {
        display: block;
    }

    /* .    */
    @keyframes slideInbottom {
        0% {
            transform: translateY(-100%);
        }

        100% {
            transform: translateY(0);
        }
    }

    @keyframes slideInTop {
        from {
            bottom: -100%;
        }

        to {
            bottom: 0%;
        }
    }


}



@media (max-width: 525px) {
    .page-content {
        margin: unset !important;
    }

    .MobileSideMargin {
        margin-left: 0 !important;
    }

    .ProfilefromContainer {
        all: unset
    }
}